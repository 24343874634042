import type { VersionLabel } from '#gql/catalogue'

/**
 * Get the Crystallize version label based on the preview mode. Can be used to retrieve unpublished content in preview mode.
 *
 * @param route - The route object to get the query parameters from. Will default to the current route, but another can be passed in for use in middleware.
 */
export default function useCrystallizeVersion(route = useRoute()): VersionLabel {
  // The options provided to usePreviewMode here are exactly equal to Nuxt's default implementation, except for the fact that useRoute is called internally by the default options. We want to pass our own route, so that we can use this composable in middleware.
  // https://github.com/nuxt/nuxt/blob/main/packages/nuxt/src/app/composables/preview.ts#L97-L114
  const previewMode = usePreviewMode({
    shouldEnable: () => route.query.preview === 'true',
    getState: (state) => {
      if (state.token !== undefined) {
        return state
      }

      state.token = Array.isArray(route.query.token)
        ? route.query.token[0]
        : route.query.token

      return state
    },
  })

  return previewMode.enabled.value
    ? 'draft'
    : 'published'
}
